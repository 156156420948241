import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BE_HOST;

const fetchGiveaway = (from, to) => {
  return axios.get(API_URL + "/invitation/report/giveaway?from=" + from + "&to=" + to, { headers: authHeader() });
};

export default {
  fetchGiveaway
};
