import {
  SET_REPORT,
  CLEAR_REPORT,
  SET_MESSAGE
} from "./types";

import ReportService from "../services/report.service";

export const fetchGiveaway = (from, to) => (dispatch) => {
  return ReportService.fetchGiveaway(from, to).then(
    (response) => {
      dispatch({
        type: SET_REPORT,
        payload: response.data.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const clearReport = () => ({
  type: CLEAR_REPORT,
});
