import React from "react";
import { useSelector } from "react-redux";

const PreviewPost = () => {
  const { post: currentPost } = useSelector((state) => {
    return state.post
  });

  return (
    <>
      {(currentPost) && (
        <div className="container">
          <h3>
            <strong>{currentPost.title}</strong>
          </h3>
          <p>
            <strong>Description:</strong> {currentPost.description}
          </p>
          <p>
            <strong>Status:</strong> {currentPost.post_status}
          </p>
          <p>
            <strong>Deleted:</strong> {String(currentPost.deleted)}
          </p>
          <p>
            <img src={currentPost.post_image[0]} style={{ width: "100%", height: '100%' }} alt='Primary post'/>
          </p>
        </div>
      )}
    </>
  );
};

export default PreviewPost;
