import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { sendBulkInvitations } from "../actions/invitation";
import { clearMessage } from "../actions/message";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const BulkInvitations = () => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();

  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector(state => state.message);
  const [load, setLoad] = React.useState(false);

  const invitation = {
    "title": "Win $500 HOME DEPOT Gift Card",
    "body": "Refer OFFLOADIT to 3 People To Enter The Giveaway!",
    "imageUrl": "https://offloadit-prod.s3.us-west-1.amazonaws.com/other/depot.png"
  }

  const onChangeTitle = (e) => {
    invitation.title = e.target.value;
  };

  const onChangeBody = (e) => {
    invitation.body = e.target.value;
  };

  const onChangeImageUrl = (e) => {
    invitation.imageUrl = e.target.value;
  };

  const sendHandler = (e) => {
    e.preventDefault();
    dispatch(clearMessage());
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      setLoad(true);
      dispatch(sendBulkInvitations(invitation))
        .then(() => {
          setSuccessful(true);
          setLoad(false);
        })
        .catch(() => {
          setSuccessful(false);
          setLoad(false);
        });
    }
  };

  const sendAgain = (e) => {
    e.preventDefault();
    dispatch(clearMessage());
  };

  return (
    <div className="col-md-12">
      <div className="card invitation-container">
        <img
          src="https://offloadit-prod.s3.us-west-1.amazonaws.com/other/invitation_logo.jpeg"
          alt="invitation_logo"
        />

        <Form onSubmit={sendHandler} ref={form}>
          {(!message) && (
            <div>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <Input
                  type="text"
                  className="form-control"
                  name="title"
                  value={invitation.title}
                  onChange={onChangeTitle}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="content">Content</label>
                <Input
                  type="text"
                  className="form-control"
                  name="content"
                  value={invitation.body}
                  onChange={onChangeBody}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="imageUrl">Image URL</label>
                <Input
                  type="text"
                  className="form-control"
                  name="imageUrl"
                  value={invitation.imageUrl}
                  onChange={onChangeImageUrl}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <button disabled={load} className="btn btn-primary btn-block">Send to all users</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {message}
              </div>
              <div className="form-group">
                <button className="btn btn-secondary btn-block" onClick={sendAgain}>Send again</button>
              </div>
            </div>
          )}

          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default BulkInvitations;
