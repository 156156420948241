import React, { useState, useRef } from "react";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import ViewGiveawayReport from "./ViewGiveawayReport";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Form from "react-validation/build/form";
import { fetchGiveaway, clearReport } from "../actions/giveaway";
import { clearMessage } from "../actions/message";

const Giveaway = () => {
  const form = useRef();
  const dispatch = useDispatch();

  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector(state => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);

  var monthAgo = new Date();
  monthAgo.setMonth(monthAgo.getMonth() - 1);
  const [startDate, setStartDate] = useState(monthAgo);
  const [endDate, setEndDate] = useState(new Date());

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const handleReport = (e) => {
    e.preventDefault();
    const from = startDate ? startDate.toISOString().split('T')[0] : ''
    const to = endDate ? endDate.toISOString().split('T')[0] : ''

    dispatch(clearMessage());
    dispatch(clearReport());

    dispatch(fetchGiveaway(from, to))
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  return (
    <div className="col-md-12">
      <div className="card report-container">

        <Form onSubmit={handleReport} ref={form} className="form-inline">
          <div className="form-group">
            <label htmlFor="from" className="col-md-2 control-label">From</label>
            <div className="col-md-3">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                name="from"
                // isClearable
                placeholderText="mm/dd/yyyy"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="to" className="col-md-2 control-label">To</label>
            <div className="col-md-3">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                name="to"
                // isClearable
                placeholderText="mm/dd/yyyy"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block" onClick={handleReport}>Report</button>
          </div>

          {message && (
            <div className="col-12" style={{ marginTop: `25px` }}>
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {message}
              </div>
            </div>
          )}

          <div className="col-12" style={{ marginTop: `25px` }}>
            <ViewGiveawayReport />
          </div>
        </Form>

      </div>
    </div>
  );
};

export default Giveaway;
