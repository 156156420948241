import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { socialLogin } from "../actions/auth";
import { Redirect } from 'react-router-dom';

const SocialSignupResult = (props) => {
  const dispatch = useDispatch();
  const [authCode, setAuthCode] = useState('');
  const { message } = useSelector(state => state.message);

  const Login = async () => {
    dispatch(socialLogin(authCode))
      .then(() => {
        props.history.push("/profile");
        window.location.reload();
      })
      .catch(() => {
        return <Redirect to="/login" />;
      });
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const code = query.get('code');

  useEffect(() => {
    setAuthCode(code)
    if (authCode) {
      Login();
    }
  }, [authCode]);

  return <div>{message}</div>;
}

export default SocialSignupResult;
