import React from "react";

const Home = () => {

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>This app is only for management purposes, only ADMINS are allowed to use it</h3>
      </header>
    </div>
  );
};

export default Home;
