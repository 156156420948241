import axios from "axios";
import ProfileService from "../services/profile.service";

const API_URL = process.env.REACT_APP_BE_HOST;

const register = (username, email, password) => {
  return axios.post(API_URL + "/signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.authFlowResponse.AuthenticationResult.AccessToken) {
        // const tokenDecodablePart = response.data.authFlowResponse.AuthenticationResult.AccessToken.split('.')[1]
        // const decoded = JSON.parse(Buffer.from(tokenDecodablePart, 'base64').toString())
        const data = {
          username: username,
          accessToken: response.data.authFlowResponse.AuthenticationResult.AccessToken,
          roles: response.data.roles,
          id: response.data.user_id,
          email: username
        }
        localStorage.setItem("user", JSON.stringify(data))
        return data
      }
      return {}
    });
};

const socialLogin = (authCode) => {
  const url = process.env.REACT_APP_BE_HOST + "/token?authorization_code=" + authCode + '&type=' + process.env.REACT_APP_COGNITO_TYPE;
  return axios
    .get(url)
    .then((response) => {
      const resp = JSON.parse(JSON.stringify(response.data))
      return ProfileService.getProfile(resp.user_id, resp.response.access_token)
      .then(
        (response) => {
          const data = {
            username: response.data._id,
            accessToken: resp.response.access_token,
            roles: response.data.roles,
            id: response.data.user_id || resp.user_id,
            email: response.data.email,
          }
          localStorage.setItem("user", JSON.stringify(data))
          return data
        },
        (error) => {
          return {}
        }
      );
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
  socialLogin,
};
