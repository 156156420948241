import React from "react";
import { useSelector } from "react-redux";

const PreviewUsers = () => {
  const { member: currentMember } = useSelector((state) => {
    return state.member
  });

  return (
    <>
      {(currentMember) && (
        <div className="container">
          <h3>
            <strong>{currentMember.name}</strong>
          </h3>
          <p>
            <strong>User ID:</strong> {currentMember.user_id}
          </p>
          <p>
            <strong>Email:</strong> {currentMember.email}
          </p>
          <p>
            <strong>Phone:</strong> {currentMember.phone}
          </p>
        </div>
      )}
    </>
  );
};

export default PreviewUsers;
