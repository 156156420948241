export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_POST = "SET_POST";
export const CLEAR_POST = "CLEAR_POST";

export const SET_MEMBER = "SET_MEMBER";
export const CLEAR_MEMBER = "CLEAR_MEMBER";

export const SET_REPORT = "SET_REPORT";
export const CLEAR_REPORT = "CLEAR_REPORT";
