import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BE_HOST;

const getProfile = (userId, accessToken) => {
  return axios.get(API_URL + "/profile/" + userId, { headers: accessToken ? { "Authorization": accessToken } : authHeader() })
};

export default {
  getProfile,
};
