import { combineReducers } from "redux";
import auth from "./auth";
import post from "./post";
import message from "./message";
import report from "./report";
import member from "./member";

export default combineReducers({
  auth,
  post,
  message,
  report,
  member
});
