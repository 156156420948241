import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import ManagePosts from "./components/ManagePosts";
import DeleteUsers from "./components/DeleteUsers";
import Giveaway from "./components/Giveaway";
import BoardModerator from "./components/BoardModerator";
import SendInvitation from "./components/SendInvitation";
import BulkInvitations from "./components/BulkInvitations";
import SocialSignupResult from "./components/SocialSignupResult";

import { logout } from "./actions/auth";
import { clearPost } from "./actions/post";
import { clearMember } from "./actions/users";
import { clearMessage } from "./actions/message";
import { clearReport } from "./actions/giveaway";

import { history } from "./helpers/history";

// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
      if (location.pathname === '/giveaway') {
        dispatch(clearReport());
      } else if (location.pathname === '/managePosts') {
        dispatch(clearPost());
      } else if (location.pathname === '/deleteUsers') {
        dispatch(clearMember());
      }
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Router history={history}>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            OFFLOADIT
          </Link>
          <div className="navbar-nav mr-auto">

            {/* <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li> */}

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <>
                <li className="nav-item">
                  <Link to={"/sendInvitation"} className="nav-link">
                    Send Invitation
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/bulkInvitations"} className="nav-link">
                    Bulk Invitations
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/managePosts"} className="nav-link">
                    Manage Posts
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/giveaway"} className="nav-link">
                    Giveaway Report
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/deleteUsers"} className="nav-link">
                    Delete Users
                  </Link>
                </li>
              </>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li> */}
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/mod" component={BoardModerator} />
            <Route path="/sendInvitation" component={SendInvitation} />
            <Route path="/bulkInvitations" component={BulkInvitations} />
            <Route path="/managePosts" component={ManagePosts} />
            <Route path="/giveaway" component={Giveaway} />
            <Route path="/deleteUsers" component={DeleteUsers} />
            <Route path="/social-signup-result" component={SocialSignupResult} />
          </Switch>
        </div>

        {/* <AuthVerify logOut={logOut}/> */}
      </div>
    </Router>
  );
};

export default App;
