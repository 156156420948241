import {
  SET_POST,
  CLEAR_POST,
  SET_MESSAGE
} from "./types";

import PostService from "../services/post.service";

export const findPost = (postId) => (dispatch) => {
  return PostService.findPost(postId).then(
    (response) => {
      dispatch({
        type: SET_POST,
        payload: response.data.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deletePost = (postId) => (dispatch) => {
  return PostService.deletePost(postId).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const clearPost = () => ({
  type: CLEAR_POST,
});
