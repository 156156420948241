import {
  SET_MEMBER,
  CLEAR_MEMBER,
  SET_MESSAGE
} from "./types";

import UserService from "../services/user.service";

export const findUserByEmail = (email) => (dispatch) => {
  return UserService.findUserByEmail(email).then(
    (response) => {
      dispatch({
        type: SET_MEMBER,
        payload: response.data.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteUserById = (userId) => (dispatch) => {
  return UserService.deleteUserById(userId).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const clearMember = () => ({
  type: CLEAR_MEMBER,
});
