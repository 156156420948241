import { SET_REPORT, CLEAR_REPORT } from "../actions/types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REPORT:
      return { report: [...initialState, ...payload] };

    case CLEAR_REPORT:
      return { report: null };

    default:
      return state;
  }
}
