import React from 'react';
import { FaGoogle } from 'react-icons/fa';

function SignInOptions() {
  const redirectUri = encodeURIComponent(process.env.REACT_APP_COGNITO_REDIRECT_URI)
  const GOOGLE_LOGIN_URL = process.env.REACT_APP_COGNITO_URL +
    "oauth2/authorize?identity_provider=Google&redirect_uri=" +
    redirectUri +
    "&response_type=CODE&client_id=" +
    process.env.REACT_APP_COGNITO_CLIENT_ID +
    "&scope=email openid profile aws.cognito.signin.user.admin"

  return (
      <a href={GOOGLE_LOGIN_URL}>
        <button className="theme-btn border-0 w-100" type="submit" >
          <i> <FaGoogle /> </i>&nbsp;Google
        </button>
      </a>
  );
}

export default SignInOptions;
