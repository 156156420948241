import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BE_HOST;

const sendInvitation = (invitation) => {
  return axios.post(API_URL + "/send_invitation", invitation, { headers: authHeader() });
};

const sendBulkInvitations = (invitation) => {
  return axios.post(API_URL + "/send_invitations", invitation, { headers: authHeader() });
};

export default {
  sendInvitation,
  sendBulkInvitations
};
