import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BE_HOST;

const getPublicContent = () => {
  return axios.get(API_URL + "/api/auth/all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "/api/auth/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "/api/auth/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "/api/auth/admin", { headers: authHeader() });
};

const deleteUserById = (userId) => {
  return axios.delete(API_URL + "/user?userId=" + userId, { headers: authHeader() });
};

const findUserByEmail = (email) => {
  return axios.get(API_URL + "/profile/user/" + email, { headers: authHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  deleteUserById,
  findUserByEmail,
};
