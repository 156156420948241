import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BE_HOST;

const deletePost = (postId) => {
  return axios.delete(API_URL + "/post/" + postId + "?isAdmin=true", { headers: authHeader() });
};

const findPost = (postId) => {
  return axios.get(API_URL + "/post/" + postId, { headers: authHeader() });
};

export default {
  deletePost,
  findPost
};
