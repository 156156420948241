import React from "react";
import { useSelector } from "react-redux";
import Table from 'react-bootstrap/Table';

const ViewGiveawayReport = () => {
  const { report: currentReport } = useSelector((state) => {
    return state.report
  });

  // get table columns
  const displayProps = ['email', 'invitation_code', 'total', 'invited']
  /* const displayProps = currentReport ? Object.keys(currentReport[0]) : []; */

  // get table heading data
  const ThData = () => {
    return displayProps.map((data) => {
      return <th key={data}>{data}</th>
    })
  }

  // custom render for 'invited'
  const displayInvited = (values) => {
    return values.map((item) => item.email || item.user_id).join(", ")
  }

  // get table row data
  const tdData = () => {
    return currentReport.map((data) => {
      return (
        <tr>
          {
            displayProps.map((v) => {
              if (v === 'invited') {
                return <td>{displayInvited(data[v])}</td>
              } else {
                return <td>{data[v]}</td>
              }
            })
          }
        </tr>
      )
    })
  }

  return (
    <>
      {(currentReport) && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>{ThData()}</tr>
          </thead>
          <tbody>
            {tdData()}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default ViewGiveawayReport;
