import React, { useState, useRef } from "react";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PreviewPost from "./PreviewPost";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { deletePost } from "../actions/post";
import { findPost, clearPost } from "../actions/post";
import { clearMessage } from "../actions/message";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ManagePosts = () => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();

  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector(state => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { post: currentPost } = useSelector((state) => state.post);

  const [state, setState] = useState({
    id: "UweFBYABr5C1KkYhqh57"
  })

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const onChangePostId = (e) => {
    setState({
      ...state,
      id: e.target.value
    })
    dispatch(clearMessage());
    dispatch(clearPost());
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(clearMessage());
    dispatch(clearPost());

    if (state.id) {
      dispatch(findPost(state.id))
        .then(() => {
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(deletePost(state.id))
        .then(() => {
          setSuccessful(true);
          dispatch(clearPost());
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  return (
    <div className="col-md-12">
      <div className="card invitation-container">

        <Form onSubmit={handleDelete} ref={form}>
          <div>
            <div className="form-group">
              <label htmlFor="id">Post ID</label>
              <Input
                type="text"
                className="form-control"
                name="id"
                value={state.id}
                onChange={onChangePostId}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block" onClick={handleSearch}>Search</button>
            </div>

            <PreviewPost />

            {(currentPost) && (
              <div className="form-group">
                <button className="btn btn-primary btn-block">Delete</button>
              </div>
            )}
          </div>

          {message && (
            <div className="form-group">
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default ManagePosts;
